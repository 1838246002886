.post {
    background-color: white;
    max-width: 900px;
    border: 1px solid lightgray;
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
}

.post__image {
    width: 100%;
    object-fit: contain;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

.post__text {
    font-weight: normal;
    padding-left: 10px;
}

.post__header {
    display: flex;
    align-items: center;
    padding-left: 5px;
    justify-content: left;
}

.post__avatar {
    margin-right: 5px;
}

.post__more {
    margin-left: auto;
    padding-right: 15px;
}

h3 {
    font-weight: normal;
}

h4 {
    font-weight: normal;
}

.editTags__tagContainer {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
}

.post__modalheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}