.app__header {
    font-family: 'Quicksand', sans-serif;
    background-color: #73A657;
    padding: 10px;
    object-fit: contain;
    border-bottom: 1px solid lightgray;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 100;
  }
  
  .app__header_authcontainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  
  .app__headerImage {
    max-width: 50%;
    max-height: 50px;
  }
  
  .app__signup {
    display: flex;
  }

  .app__header__back {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  