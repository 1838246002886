.login {
    text-align: center;
}

.login__header_img {
    display: block;
    padding-top: 15vh;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.login__subtitle {
    color: white;
}

.login__signin_container {
    padding-top: 10vh;
    font-weight: 600;
}

.login__bottom_text {
    width: 100%;
    position: absolute;
    bottom: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 5px;
}
