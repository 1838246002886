* {
  margin: 0,
}

.app {
  background-color: #fafafa;
  height: 90vh;
}

.app__login {
  height: 100vh;
  background: rgb(0,208,158);
  background: linear-gradient(180deg, rgba(0,208,158,1) 0%, rgba(0,255,147,1) 100%);
}

