.upload__addImageForm {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.upload__modal__header {
    display: flex;
    align-items: center;
    text-align: center;
}

h2 {
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
}

h4 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.upload__modal_progressBar {
    width: 100%;
}

.upload__addCaption {
    width: 100%;
    margin-top: 3px;
}

.upload__modal__submitButton {
    width: 100%;
    margin-top: 10px;
}

.upload__modal__tags {
    display: flex;
    flex-direction: row;  
    flex-wrap: wrap;
    justify-content: center; 
}